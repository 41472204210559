import { baseFetch } from "../../base/index.js";

/**
 * @typedef {{
 *           type:string,
 *           id:number,
 *           city:string,
 *           image:string,
 *           phone: string,
 *           address: string,
 *           metro: string[],
 *           reviews: {
 *               yandex:string,
 *               "2gis":string
 *           },
 *           worktime: {
 *              period: string,
 *              time: string
 *           }[],
 *           gallery: string[],
 *           geometry: {
 *               type:string,
 *               coordinates:[x:number,y:number]
 *           },
 *           properties: {
 *               balloonContentHeader: string,
 *               balloonContentBody: string,
 *               hintContent: string
 *           },
 *           options: {
 *               preset: string
 *           }
 *      }} MapResponseFeature
 */

/**
 * @typedef {{
 * cities: string[],
 * points: {
 *      type:string,
 *      features: MapResponseFeature[]
 *      }
 * }} MapResponse
 */

/**
 * @typedef {{
 * countries: {
 *      [key:string]: {cities: string[]}
 * },
 * points: {
 *      type:string,
 *      features: MapResponseFeature[]
 *      }
 * }} MapWithCountriesResponse
 */

const endpoints = {

    /**
     *
     * @returns {Promise<MapResponse>} geopoints
     */
    getDataMapCosmetologist: async () => baseFetch.post("/local/ajax/getDataMapCosmetologist.php"),
    /**
     *
     * @returns {Promise<MapResponse>} geopoints
     */
    getDataMap: async () => baseFetch.post("/local/ajax/getDataMap.php"),
    /**
     *
     * @returns {Promise<MapWithCountriesResponse>} geopoints
     */
    getCdkMap: async () => baseFetch.post("/local/ajax/getCdkMap.php"),
};

export default endpoints;
