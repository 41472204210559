import { BaseYMap } from "../../modules/ymap/index.js";

function BaseMap(center, container) {
    this.mapContainer = container;

    this.center = center;

    if (!this.mapContainer) return;

    this.map = new BaseYMap(this.mapContainer);
}

export function initWhereToBuyMapByFeatures(features) {
    const mapContainer = document.querySelector(".cities-tabs__map");
    if (!mapContainer) return null;

    const whereToBuyMap = new BaseMap([0, 0], mapContainer);

    features.forEach((feature) => {
        const coordinates = feature.geometry.coordinates.reverse();

        const content = {
            name: feature.properties.balloonContentHeader,
            content: feature.properties.balloonContentBody,
        };

        whereToBuyMap.map.addMark(coordinates, content);
    });

    return whereToBuyMap.map;
}
