/* eslint-disable indent */
import Swiper from "swiper";
import { initMapSliderWithCitiesTabs } from "../../modules/map-slider-with-cities-tabs/index.js";
import { API } from "../../services/api/endpoints/index.js";
import { initTabList } from "../../ui/tabs/index.js";
import { initWhereToBuyMapByFeatures } from "./map.js";

const cititesTabs = document.querySelector(".cities-tabs.where-to-buy");

function initSlider(slider) {
    const id = slider.id;

    return new Swiper(`#${id}`, {
        slidesPerView: "auto",
        slideClass: "cities-slider__item",
        navigation: {
            prevEl: `#${id} .cities-slider-arrows-left`,
            nextEl: `#${id} .cities-slider-arrows-right`,
        },
        breakpoints: {
            992: {
                allowTouchMove: true,
            },
        },
    });
}

function initCitiesSliders() {
    const sliders = document.querySelectorAll(".cities-slider");
    if (!sliders.length) return;

    sliders.forEach((slider, index) => {
        slider.id = `cities-slider-${index}`;
        initSlider(slider);
    });
}

function generateCountriesTabs(countries) {
    if (!cititesTabs) return null;

    const countriesTabsBlock = document.createElement("div");

    countriesTabsBlock.classList.add("tabs", "countries-tabs", "where-to-buy");

    countriesTabsBlock.id = "countriesTabs";

    countriesTabsBlock.innerHTML = `
        <div class="tabs__tab-list-wrapper swiper cities-slider">
            <ul class="tabs__tab-list swiper-wrapper cities-slider__wrapper">
                ${countries.map((country, index) => (
        `
                        <li data-country="${country}" data-value=${index}
                        class="cities-slider__item tabs__tab-list-item${index === 0 ? "--selected" : ""}"
                        >
                            <button class="tabs__tab-button">
                                ${country}
                            </button>
                        </li>
                    `
    )).join(" ")
        }
            </ul>
            <div class="slider-navigation cities-slider__arrows">
                <button class="slider-navigation-prev cities-slider-arrows-left" disabled="">
                    <i class="icon-arrow-left"></i>
                </button>
                <button class="slider-navigation-next cities-slider-arrows-right">
                    <i class="icon-arrow-right"></i>
                </button>
            </div>
        </div>
        <ul class="tabs__tab-panel-list">
        </ul>
    `;

    return countriesTabsBlock;
}

function generateCountryTabPanel({ country, index, countryData }) {
    const tabPanel = document.createElement("div");

    const citiesPoints = document.querySelector(".cities-points");

    tabPanel.classList.add(index === 0 ? "tabs__tab-panel-item--show" : "tabs__tab-panel-item");

    const cities = [];

    // remove dublicates
    countryData.cities.forEach((cityData) => {
        const cityName = Object.keys(cityData)[0];
        if (!cities.find((c) => Object.keys(c)[0] === cityName)) {
            cities.push(cityData);
        }
    });

    tabPanel.dataset.value = index;

    if (countryData.cities.length > 0) {
        const countryCitiesTabs = cititesTabs.cloneNode(true);
        countryCitiesTabs.id = `citiesTabs-${country}`;
        countryCitiesTabs.classList.add("tabs");

        const tabList = countryCitiesTabs.querySelector(".tabs__tab-list");

        cities.forEach((city, i) => {
            const newTab = `
                <li data-center="${city[Object.keys(city)[0]].coordinates}" data-city="${Object.keys(city)[0]}" data-value=${i}
                    class="cities-slider__item tabs__tab-list-item${i === 0 ? "--selected" : ""}"
                >
                    <button class="tabs__tab-button">
                        ${Object.keys(city)[0]}
                    </button>
                </li>
            `;
            tabList.innerHTML += newTab;
        });
        tabPanel.append(countryCitiesTabs);
    }

    return tabPanel;
}

export async function initWhereToBuy(params) {
    if (!cititesTabs) return;

    const mapResponse = await API.map.getDataMap();

    const mapData = await mapResponse.json();

    const countries = mapData.countries;

    const countriesList = Object.keys(countries);

    const countriesTabsBlock = generateCountriesTabs(countriesList);

    const tabPanelList = countriesTabsBlock.querySelector(".tabs__tab-panel-list");

    const whereToBuyMap = initWhereToBuyMapByFeatures(mapData.points.features);

    const citiesPoints = document.querySelector(".cities-points");

    countriesList.map((country, index) => {
        const tabPanel = generateCountryTabPanel({
            country,
            index,
            countryData: countries[country],
        });

        tabPanelList.append(tabPanel);
    });

    cititesTabs.replaceWith(countriesTabsBlock);

    mapData.points.features.forEach((feature) => {
        if (feature.city && citiesPoints) {
            const cityPoint = `
                <div class="cities-points__city" data-city="${feature.city}">
                    <h3 class="cities-points__heading">${feature.city}</h3>
                    <ul class="cities-points__list">
                    </ul>
                </div>
            `;

            citiesPoints.innerHTML += cityPoint;

            const cityPointList = document.querySelector(`.cities-points__city[data-city="${feature.city}"] .cities-points__list`);
            if (cityPointList) {
                const content = {
                    name: feature.properties.balloonContentHeader,
                    content: feature.properties.balloonContentBody,
                };

                const citiesPointsItem = `
                    <li class="cities-points__list-item">
                        <div class="cities-points__list-item-heading">
                            ${content.name}
                        </div>
                        ${content.content}
                    </li>
                `;

                cityPointList.innerHTML += citiesPointsItem;
            }
        } else if (feature.country) {
            const cityPoint = `
                <div class="cities-points__city" data-country="${feature.country}">
                    <h3 class="cities-points__heading">${feature.country}</h3>
                    <ul class="cities-points__list">
                    </ul>
                </div>
            `;

            citiesPoints.innerHTML += cityPoint;

            const cityPointList = document.querySelector(`.cities-points__city[data-country="${feature.country}"] .cities-points__list`);
            if (cityPointList) {
                const content = {
                    name: feature.properties.balloonContentHeader,
                    content: feature.properties.balloonContentBody,
                };

                const citiesPointsItem = `
                    <li class="cities-points__list-item">
                        <div class="cities-points__list-item-heading">
                            ${content.name}
                        </div>
                        ${content.content}
                    </li>
                `;

                cityPointList.innerHTML += citiesPointsItem;
            }
        }
    });

    countriesTabsBlock.addEventListener("tab-change", ({ detail }) => {
        const { currentTab } = detail;

        // const citiesTabs = currentTabPanel.querySelector(".cities-tabs");

        const country = currentTab.dataset.country;

        const countryCoordinates = countries[country].coordinates;

        if (citiesPoints) {
            const prevCityPoin = document.querySelector(".cities-points__city--selected");

            if (prevCityPoin) prevCityPoin.classList.remove("cities-points__city--selected");
        }

        if (countryCoordinates) {
            const center = countryCoordinates.slice().reverse();
            whereToBuyMap.setCenter(center, 7);

            if (citiesPoints) {
                const currentCityPoint = document.querySelector(`.cities-points__city[data-country="${country}"]`);

                currentCityPoint.classList.add("cities-points__city--selected");
            }
        } else {
            const currentTabPanel = currentTab.parentNode.parentNode.parentNode.querySelector(
                `.tabs__tab-panel-item--show[data-value="${currentTab.dataset.value}"], 
                .tabs__tab-panel-item[data-value="${currentTab.dataset.value}"]`,
            );
            const currentSelectedCity = currentTabPanel.querySelector(".tabs__tab-list-item--selected");

            const cityCenter = currentSelectedCity.dataset.center.split(",").reverse();
            whereToBuyMap.setCenter(cityCenter, 11);

            if (citiesPoints) {
                const city = currentSelectedCity.dataset.city;

                const currentCityPoint = document.querySelector(`.cities-points__city[data-city="${city}"]`);

                currentCityPoint.classList.add("cities-points__city--selected");
            }
        }
    });

    const countryCitiesTabs = countriesTabsBlock.querySelectorAll(".cities-tabs");

    countryCitiesTabs.forEach((citiesTabs) => {
        citiesTabs.addEventListener("tab-change", ({ detail }) => {
            const { currentTab } = detail;

            const isCurrentCountry = currentTab.closest(".tabs__tab-panel-item--show") !== null;

            if (isCurrentCountry) {
                const cityCenter = currentTab.dataset.center.split(",").reverse();
                whereToBuyMap.setCenter(cityCenter, 11);

                if (citiesPoints) {
                    const city = currentTab.dataset.city;
                    const prevCityPoin = document.querySelector(".cities-points__city--selected");

                    if (prevCityPoin) prevCityPoin.classList.remove("cities-points__city--selected");

                    const currentCityPoint = document.querySelector(`.cities-points__city[data-city="${city}"]`);

                    currentCityPoint.classList.add("cities-points__city--selected");
                }
            }
        });
        initTabList(citiesTabs);
    });

    initTabList(countriesTabsBlock);

    initCitiesSliders();

    // console.log(mapData.points.features.filter(f=>f.city == null))
    // await initMapSliderWithCitiesTabs({
    //     endpoint: ,
    //     className: "where-to-buy",
    // });
}
