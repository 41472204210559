/**
 *
 * @param {Element} tabList element with class "tabs__tab-list"
 * @param {Element} tab element with class "tabs__tab-list-item"
 */
function moveIndicatorToTab(tabList, tab) {
    const tabListWrapper = tabList.parentNode;

    const indicator = tabListWrapper.querySelector(".tabs__indicator");

    if (!indicator) return;

    const tabListRect = tabList.getBoundingClientRect();

    const currentTabRect = tab.getBoundingClientRect();

    indicator.style.width = `${currentTabRect.width}px`;
    indicator.style.translate = `${currentTabRect.left - tabListRect.left + tabList.scrollLeft}px 0`;
}

/**
 *
 * @param {Element} tabs element with class "tabs"
 */
export function initTabIndicator(tabs) {
    // tabs.scrollTo({ left: 0, behavior: "instant" });
    const tabList = tabs.querySelector(".tabs__tab-list");

    const tabListWrapper = tabList.parentNode;

    const indicator = tabListWrapper.querySelector(".tabs__indicator");

    if (!indicator) return;

    const currentTab = tabList.querySelector(".tabs__tab-list-item--selected");

    if (!currentTab) return;

    moveIndicatorToTab(tabList, currentTab);

    tabList.scrollTo({ left: currentTab.offsetLeft, behavior: "instant" });
}

/**
 *
 * @param {Element} tabs element with class "tabs"
 */
function showCurrentTabPanel(tabs) {
    const tabList = tabs.querySelector(".tabs__tab-list");

    const currentTab = tabList.querySelector(".tabs__tab-list-item--selected");

    if (!currentTab) return;

    const tabsChangeEvent = new CustomEvent("tab-change", {
        detail: {
            currentTab,
        },
    });

    tabs.dispatchEvent(tabsChangeEvent);

    const tabPanelList = tabs.querySelector(".tabs__tab-panel-list");

    if (!tabPanelList) return;

    const tabPanels = tabPanelList.querySelectorAll(".tabs__tab-panel-item, .tabs__tab-panel-item--show");

    const currentTabValue = currentTab.dataset.value;

    const currentTabPanel = tabPanelList.querySelector(".tabs__tab-panel-item--show");

    if (currentTabPanel) {
        const currentTabPanelValue = currentTabPanel.dataset.value;

        if (currentTabValue === currentTabPanelValue) return;

        currentTabPanel.classList.replace("tabs__tab-panel-item--show", "tabs__tab-panel-item");
    }

    tabPanels.forEach((tabPanel) => {
        const tabPanelValue = tabPanel.dataset.value;

        if (!tabPanelValue) throw new Error("tabs__tab-panel-item must contain data-value attr");

        if (tabPanelValue === currentTabValue) {
            tabPanel.classList.replace("tabs__tab-panel-item", "tabs__tab-panel-item--show");
        }
    });
}

/**
 *
 * @param {MouseEvent} event
 */
function onTabClick(event) {
    event.stopPropagation();

    const targetTab = event.currentTarget;

    const tabList = targetTab.parentNode;

    const tabs = tabList.parentNode.parentNode;

    const currentTab = tabList.querySelector(".tabs__tab-list-item--selected");

    if (!targetTab.dataset.value) throw new Error("tab must have data-value attr");

    tabList.dataset.value = targetTab.dataset.value;

    if (currentTab) {
        if (targetTab.dataset.value === currentTab.dataset.value) return;

        currentTab.classList.replace("tabs__tab-list-item--selected", "tabs__tab-list-item");
    }

    targetTab.classList.replace("tabs__tab-list-item", "tabs__tab-list-item--selected");

    moveIndicatorToTab(tabList, targetTab);

    showCurrentTabPanel(tabs);
}

/**
 *
 * @param {Element} tabs element with class "tabs"
 */
export function initTabList(tabs) {
    const tabList = tabs.querySelector(".tabs__tab-list");

    const tabListItems = tabList.querySelectorAll(".tabs__tab-list-item, .tabs__tab-list-item--selected");

    tabListItems.forEach((item) => {
        item.addEventListener("click", onTabClick);
    });

    initTabIndicator(tabs);
    showCurrentTabPanel(tabs);
}

export function initTabs() {
    const tabsBlocks = document.querySelectorAll(".tabs");
    tabsBlocks.forEach((tabs) => {
        initTabList(tabs);
        window.addEventListener("resize", () => initTabIndicator(tabs));
    });
}
